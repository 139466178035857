.refInput-container {
  display: flex;
  justify-content: space-between;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ref-input, .save-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  max-width: 50%;
}

.ref-input input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.refInput-container button {
  background-color: #61e261;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.refInput-container button:hover {
  background-color: #3fbe3f;
}


.ticker{
  margin-bottom: 15px;
  padding: 10px;
  text-align: center;
  justify-self: center;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-weight: normal;
}

@media (max-width: 480px) {
  .refInput-container button{
    border-radius: 20px;
  }
}