.generate-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 5px 0;
}

.generate-container button {
  background-color: #61e261;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.generate-container button:hover {
  background-color: #3fbe3f;
}

.generate-container h3 {
  margin: 0;
  font-size: 20px;
  color: #333;
  font-weight: 300;
  font-size: 25px;
}

.generate-container p {
  font-size: 20px;
  color: #555;
  border:1px solid #f3f3f3;
  color: #fff;
  padding: 0 10px;
  background-color: #1893FF;
  border-radius:50px ;
}

.ref-count,
.ref-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {

  .generate-container button{
    width: 45%;
    border-radius: 20px;
  }
}
