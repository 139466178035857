@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100%;
  font-family: "Bebas Neue", sans-serif;
  background-color: #f9f9f9;
}

.main-container {
  display: flex;
  justify-content: flex-start;
}

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  margin: 0px 75px;
}

.sidebar-section {
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.header-btn {
  display: flex;
  gap: 20px;
  margin: 0 20px;
}

.header-info {
  display: flex;
  gap: 80px;
  align-items: center;
}

.header-info h2 {
  color: #131313;
  font-weight: normal;
}

.header img {
  width: 80px;
  margin-left: -10px;
  max-height: 100%;
}

.x-section {
  display: grid;
  gap: 10px;
}

.share-post,
.ref-section,
.leaderboard,
.task-section {
  border: 1px solid #e7e5e5;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  height: 300px;
  width: 500px;
  overflow-y: auto;
}

.last-posts {
  border: 1px solid #e7e5e5;
  background-color: #fff;
  border-radius: 10px;
}

.share-post {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.share-post textarea {
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.post-share,
.task-section h2 {
  color: #679ff4;
}

.primary-btn {
  background-color: #61e261;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
  transition: background-color 0.3s;
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.2rem;
}

.primary-btn:hover {
  background-color: #3fbe3f;
}

.post-count {
  margin-top: 20px;
  font-size: 1.3rem;
}

.warning-message {
  background-color: #ddffeb;
  border-left: 6px solid #4cf436;
  color: #00d824;
  padding: 5px;
  text-align: center;
  margin-top: 10px;
  border-radius: 4px;
  font-size: 1.2rem;
}

.ref-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.users-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-button {
  background-color: #679ff4;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
  font-size: 16.7px;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  padding: 0 24px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #507edb;
}

::-webkit-scrollbar {
  width: 5px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: #eaeaea;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }

  .main {
    grid-template-columns: 1fr;
    margin: 0 15px;
  }

  .sidebar-section {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

  .sidebar-section .socials {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    gap: 20px;
  }

  .share-post,
  .ref-section,
  .leaderboard,
  .task-section {
    width: 100%;
  }

  .last-posts {
    margin: 0 15px;
    margin-top: 10px;
  }
}

@media (max-width: 694px) {
  .header-btn button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-btn {
    flex-direction: column;
    gap: 5px;
  }

  .header-info {
    flex-direction: column;
    gap: 5px;
  }
}

@media (max-width: 480px) {
  .main {
    margin: 0 15px;
  }

  .header-btn button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-btn {
    flex-direction: column;
    gap: 5px;
  }

  .header-info {
    flex-direction: column;
    gap: 5px;
  }

  .share-post textarea {
    height: 60px;
  }

  .ref-section {
    overflow: hidden;
    height: auto;
  }

  .primary-btn {
    width: 100%;
  }

  .login-button {
    width: 100%;
  }
}

.Toastify {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
}
