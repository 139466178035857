.task-button {
  padding: 8px 16px;
  background-color: #008cba;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  margin-left: 10px;
  display: inline-block;
}

.task-button.disabled {
  background-color: rgb(178, 250, 178);
  pointer-events: none;
  color: #ffffff;
  padding: 8px 12px;
}

.task {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.3rem;
  transition: background-color 0.15s ease;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.task:hover {
  background-color: #72d772;
}

.task-section h2 {
  display: flex;
  justify-content: center;
  color: #1893ff;
}

@keyframes slide-down {
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
