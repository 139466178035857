.leaderboard-container h2 {
  display: flex;
  justify-content: center;
  color: #679ff4;
}

.leaderboard-list {
  list-style-type: none;
  padding: 0;
}

.leaderboard-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.15s ease;
}

.leaderboard-item:hover {
  background-color: #96ceff;
}

.leaderboard-username {
  font-size: 20px;
}

.leaderboard-points {
  font-style: italic;
  font-weight: bold;
}

.spacer {
  height: 30px;
}

.current-user {
  background-color: #96ceff;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  color: #1d1d1d;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 20px;
}
