@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");

.tweet-list {
  border-radius: 8px; /* Köşeleri yuvarla */
  padding: 10px; /* İçerik etrafında boşluk bırak */
  width: 250px; /* Maksimum genişlik ayarla */
  font-family: "Kanit", sans-serif;
  font-weight: bold;
  font-style: normal;
}

.tweet-list h2 {
  color: #679FF4; /* Başlık rengini ayarla */
  margin-bottom: 5px; /* Başlık altına boşluk bırak */
  display: flex;
  justify-content: center;
  font-family: "Bebas Neue", sans-serif;}

.tweet-list ul {
  list-style-type: none; /* Liste işaretlerini kaldır */
  padding: 0; /* Liste içi boşlukları kaldır */
}

.tweet-list li {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  transition: transform 0.2s ease;
  max-height: 70px; /* Belirli bir yükseklik verelim */
  overflow-y: auto; /* Yalnızca dikeyde kaydırma özelliği */
}

.tweet-content {
  font-size: 1rem;
  white-space: normal; /* Metni çok satırlı yap */
  overflow: hidden; /* Taşan metni gizle */
}

.tweet-list li:hover {
  transform: translateY(-2px); /* Hover durumunda hafifçe yukarı kaldır */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Hover durumunda gölge ekle */
}

.tweet-list strong {
  color: #007bff; /* Kullanıcı adı rengini ayarla */
  font-weight: bold;
  margin-right: 5px; /* Kullanıcı adı ile tweet metni arasında boşluk bırak */
}

@media (max-width: 768px) {
  /* Tabletler için */
  .tweet-list {
    width: 100%;
    padding: 20px;
    font-weight: normal;
  }

  .tweet-list li {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  /* Mobil cihazlar için */
  .tweet-list {
    width: 100%;
    padding: 20px;
  }

  .tweet-list li {
    padding: 8px;
    font-size: 0.85rem;
  }
}
