.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
}


.modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 50px 40px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.modal input {
  padding: 10px;
  border: 1px solid #eae8e8; 
  border-radius: 4px;   
  width: 100%;           
  box-sizing: border-box; 
}

.modal .save-btn{
  margin-top: 10px;
  padding:  10px 20px;
  cursor: pointer;
  color: #fff;
  border: none;
  background-color: #7250c1;
  border-radius: 4px;
  font-size: 15px;
}

.close-button {
  font-size: 15px;
  background-color: #7250c1;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  margin-top: 10px;
}
