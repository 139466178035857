.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.socials {
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  text-align: center;
  justify-content: center;
}

.socials a {
  margin-top: 20px;
  color: #333;
  transition: background-color 0.3s;
  transition: transform 0.3s ease, color 0.3s ease-in-out;

}
.socials a:hover {
  transform: scale(1.2);
  color: #7461a1;
}
